import Dashboard2 from "./Pages/Dashboard2";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <Dashboard2 />
    </>
  );
}
export default App;